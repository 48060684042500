import {
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autoformat,
    Autosave,
    BlockQuote,
    Bold,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    SelectAll,
    SourceEditing,
    SpecialCharacters,
    Strikethrough,
    Style,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextPartLanguage,
    TextTransformation,
    Underline,
    Undo
} from 'ckeditor5';
import { APIUrl } from '../../../../utils/API/fetchBaseQuery';

class MyCustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    async upload() {
        try {
            const file = await this.loader.file;
            const response = await this._sendRequest(file);

            // Check if the response has an error
            if (!response || response?.error) {
                window.alert(`Upload failed: ${response?.error?.message}`)
                throw new Error(response?.error ? response?.error?.message : 'Upload failed.');
            }
            return {
                default: response?.location // The server should return the image URL
            };
        } catch (error) {
            window.alert(`Upload failed: ${error?.message}`)
            throw new Error(`Upload failed: ${error?.message}`);
        }
    }

    async _sendRequest(file) {
        const formData = new FormData();
        formData.append('file', file); // Ensure the name here matches the parameter name in your API method

        const response = await fetch(`${APIUrl}Common/CKEditorImageUpload`, {
            method: 'POST',
            body: formData,
            // headers: {
            //     // 'Content-Type': 'multipart/form-data' // DO NOT set this header, browser will set it automatically
            // },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json(); // Parse the JSON response
    }
}

function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyCustomUploadAdapter(loader);
    };
}

export const editorConfig = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'bulletedList',
            'numberedList',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'strikethrough',
            'fontFamily',
            '|',
            'imageInsert',
            'mediaEmbed',
            'insertTable',
            'sourceEditing',
            'superscript',
            'specialCharacters',
            'link',
            'codeBlock',
            '|',
            'alignment'
        ],
        shouldNotGroupWhenFull: false
    },
    image: {
        toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage'
        ]
    },
    plugins: [
        AccessibilityHelp,
        Alignment,
        AutoImage,
        AutoLink,
        Autoformat,
        Autosave,
        BlockQuote,
        Bold,
        CloudServices,
        Code,
        CodeBlock,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        GeneralHtmlSupport,
        Heading,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        SelectAll,
        SourceEditing,
        SpecialCharacters,
        Strikethrough,
        Style,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextPartLanguage,
        TextTransformation,
        Underline,
        Undo,
        CustomUploadAdapterPlugin
    ],
    fontFamily: {
        supportAllValues: true
    },
    fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true
    },
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
        ]
    },
    htmlSupport: {
        allow: [
            {
                name: /^.*$/,
                styles: true,
                attributes: true,
                classes: true
            }
        ]
    },
    link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
            toggleDownloadable: {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                    download: 'file'
                }
            }
        }
    },
    placeholder: 'Type or paste your content here!',
    style: {
        definitions: [
            { name: 'Article category', element: 'h3', classes: ['category'] },
            { name: 'Title', element: 'h2', classes: ['document-title'] },
            { name: 'Subtitle', element: 'h3', classes: ['document-subtitle'] },
            { name: 'Info box', element: 'p', classes: ['info-box'] },
            { name: 'Side quote', element: 'blockquote', classes: ['side-quote'] },
            { name: 'Marker', element: 'span', classes: ['marker'] },
            { name: 'Spoiler', element: 'span', classes: ['spoiler'] },
            { name: 'Code (dark)', element: 'pre', classes: ['fancy-code', 'fancy-code-dark'] },
            { name: 'Code (bright)', element: 'pre', classes: ['fancy-code', 'fancy-code-bright'] }
        ]
    },
    menuBar: {
        isVisible: true
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
    }
};
