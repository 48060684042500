import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import OrganisationDetails from './component/organisationDetails/OrganisationDetails';
import Buttons from '../../components/ui/button/Buttons';
import CorporateAccountInformation from './component/accountInformation/CorporateAccountInformation';
import CorporateMembershipSubscription from './component/membershipSubscription/CorporateMembershipSubscription';
import CorporatePointOfContact from './component/pointOfContact/CorporatePointOfContact';
import { useNavigate, useParams } from 'react-router-dom';
import CorporateAccountInformationData from './component/accountInformation/data/CorporateAccountInformation.data';
import CorporateMembershipSubscriptionData from './component/membershipSubscription/data/MembershipSubscription.data';
import OrganisationDetailsData from './component/organisationDetails/data/OrganisationDetails.data';
import CorporatePointOfContactData from './component/pointOfContact/data/CorporatePointOfContact.data';
import { decryptAESID } from '../../utils/Encryption';
import { setViewCorporateMemberDropDown } from './utils/setViewCorporateMemberDropDown';
import ToastService from '../../services/toastService/ToastService';
import { saveAs } from 'file-saver';
import SwalAlert from '../../services/swalService/SwalService';
import { useUploadPDFMutation, useLazyGetPDFQuery } from '../../app/services/Common/CommonApi';
import FinancialRecord from "../../features/viewIndividualMember/component/finacialRecord/FinancialRecord";
import SuperUserLoginModal from './component/SuperUserLoginModal';
import { getCookie } from '../../utils/Cookies/CookieHandler';
//import SuperUserLoginModalData from './data/SuperUserLoginModal.data';

// Import API services
import {
  useLazyGetCorporateMemberDetailByIdQuery,
  useLazyGetCorporateSIISAccountInfoQuery,
  useUpdateCorporateMemberByIdMutation,
} from '../../app/services/Corporate/ViewCorporateMemberApi';
import { useLazyGetAllDropdownsForCorporateMemberQuery } from '../../app/services/Corporate/CorporateTaskListApi';
import CenterModel from '../../components/ui/centerModel/CenterModel';

const EditCorporateMembersForm = () => {

  const navigate = useNavigate()
  const { id } = useParams();
  const { error } = SwalAlert()
  const corporateMemberId = decryptAESID(id)
  const [accountInformationForm, setAccountInformationForm] = useState(CorporateAccountInformationData);
  const [membershipSubscriptionForm, setMembershipSubscriptionForm] = useState(CorporateMembershipSubscriptionData);
  const [organisationDetailsForm, setOrganisationDetailsFormForm] = useState(OrganisationDetailsData);
  const [pointOfContactForm, setPointOfContactForm] = useState(CorporatePointOfContactData);
  const [acraFileName, setACRAFileName] = useState(null);
  const [isCorporateMember, setIsCorporateMember] = useState(true);
  const [uenNumber, setUENNumber] = useState("");

  const accountInformationRef = useRef()
  const membershipSubscriptionRef = useRef()
  const organisationDetailsRef = useRef()
  const pointOfContactFRef = useRef()

  useEffect(() => {
    getCorporateSIISAccountInfo(corporateMemberId);
  }, [corporateMemberId])

  useEffect(() => {
    getAllDropDowns()
  }, []);

  const [getAllDropDowns, {
    data: dropdownResponse,
    isSuccess: dropdownIsSuccess,
    isLoading: dropdownIsLoading,
  }] = useLazyGetAllDropdownsForCorporateMemberQuery();

  const [getMemberDetails, {
    data: getMemberDetailsData,
    isSuccess: getMemberDetailsIsSuccess,
    isLoading: getMemberDetailsIsLoading,
  }] = useLazyGetCorporateMemberDetailByIdQuery();

  const [getCorporateSIISAccountInfo, {
    data: getCorporateSIISAccountInfoData,
    isSuccess: getCorporateSIISAccountInfoIsSuccess,
    isLoading: getCorporateSIISAccountInfoIsLoading,
  }] = useLazyGetCorporateSIISAccountInfoQuery();

  const [updateCorporateMemberById, {
    data: updateCorporateMemberByIdData,
    isSuccess: updateCorporateMemberByIdIsSuccess,
    isLoading: updateCorporateMemberByIdIsLoading,
  }] = useUpdateCorporateMemberByIdMutation();

  const [uploadPDF,
    { isSuccess: isUploadSuccess,
      isLoading: isUploadLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }
  ] = useLazyGetPDFQuery();


  useEffect(() => {
    if (!isUploadSuccess && isUploadLoading && fileUploadData) {
      ToastService.success("PDF uploaded successfully !")
    }
  }, [isUploadSuccess, isUploadLoading, fileUploadData,]);


  useEffect(() => {
    if (!updateCorporateMemberByIdIsLoading && updateCorporateMemberByIdIsSuccess && updateCorporateMemberByIdData) {
      getMemberDetails(corporateMemberId);
      ToastService.success("Update Successfully")
    }
  }, [updateCorporateMemberByIdIsLoading, updateCorporateMemberByIdIsSuccess, updateCorporateMemberByIdData])

  useEffect(() => {
    if (!getCorporateSIISAccountInfoIsLoading && getCorporateSIISAccountInfoIsSuccess && getCorporateSIISAccountInfoData) {
      setAccountInformationFormInitials();
    }
  }, [getCorporateSIISAccountInfoIsLoading, getCorporateSIISAccountInfoIsSuccess, getCorporateSIISAccountInfoData])


  useEffect(() => {
    if (!getMemberDetailsIsLoading && getMemberDetailsIsSuccess && getMemberDetailsData) {
      setACRAFileName(getMemberDetailsData.acraBizFileName);
      setUENNumber(getMemberDetailsData.uneNo);
      setMembershipSubscriptionFormInitials();
      setOrganisationDetailsFormInitials();
      setPointOfContactFormInitials();
    }
  }, [getMemberDetailsIsLoading, getMemberDetailsIsSuccess, getMemberDetailsData])

  useEffect(() => {
    if (!dropdownIsLoading && dropdownIsSuccess && dropdownResponse) {
      setViewCorporateMemberDropDown(dropdownResponse);
      getMemberDetails(corporateMemberId);
    }
  }, [dropdownIsLoading, dropdownIsSuccess, dropdownResponse]);


  const setAccountInformationFormInitials = () => {
    let temp = { ...accountInformationForm }
    let tmepInitials = temp.initialState;
    tmepInitials.accountStatus = getCorporateSIISAccountInfoData?.accountStatus;
    tmepInitials.reasonForLeaving = getCorporateSIISAccountInfoData?.deletionReasonId;
    tmepInitials.joinedDate = getCorporateSIISAccountInfoData?.corporateJoinedDate;
    tmepInitials.updatedDate = getCorporateSIISAccountInfoData?.updatedDate;
    tmepInitials.updatedBy = getCorporateSIISAccountInfoData?.updatedBy;
    tmepInitials.isCorporateQRScan = getCorporateSIISAccountInfoData?.isQRScan;
    tmepInitials.isBlockDataPurging = getCorporateSIISAccountInfoData?.isBlockDataPurging;
    setAccountInformationForm(temp);
  }

  const setMembershipSubscriptionFormInitials = () => {
    let temp = { ...membershipSubscriptionForm }
    let tmepInitials = temp.initialState;
    tmepInitials.membershipType = getMemberDetailsData.membershipTypeId;
    tmepInitials.membershipTenure = getMemberDetailsData.membershipTenureId;
    tmepInitials.preferredStartDate = getMemberDetailsData.startMembershipOn;
    tmepInitials.membershipStartDate = getMemberDetailsData.subscriptionStartDate;
    tmepInitials.membershipEndDate = getMemberDetailsData.subscriptionEndDate;
    tmepInitials.billingCategory = getMemberDetailsData.corporateMemberBillingCategoryId;
    tmepInitials.membershipId = getMemberDetailsData.membershipId
    setMembershipSubscriptionForm(temp);
  }
  const setOrganisationDetailsFormInitials = () => {
    let temp = { ...organisationDetailsForm }
    let tmepInitials = temp.initialState;
    tmepInitials.nameOfOrganisationAsInAcra = getMemberDetailsData.organisationName;
    tmepInitials.brnUenNo = getMemberDetailsData.uneNo;
    tmepInitials.organisationAddressLine1 = getMemberDetailsData.addressLine1;
    tmepInitials.organisationAddressLine2 = getMemberDetailsData.addressLine2;
    tmepInitials.organisationAddressLine3 = getMemberDetailsData.addressLine3;
    tmepInitials.postalCode = getMemberDetailsData.postalCode;
    tmepInitials.industryCode = getMemberDetailsData.industryCodeId;
    tmepInitials.acraBizFile = getMemberDetailsData.acraBizFileName;
    setOrganisationDetailsFormForm(temp);
  }
  const setPointOfContactFormInitials = () => {
    let temp = { ...pointOfContactForm }
    let tmepInitials = temp.initialState;
    tmepInitials.salutationsID = getMemberDetailsData.salutationId;
    tmepInitials.nameOfContactPerson = getMemberDetailsData.nameOfContactPerson;
    tmepInitials.lastName = getMemberDetailsData.lastName;
    tmepInitials.contactNumber = getMemberDetailsData.contactNo;
    tmepInitials.contactPersonsDesignation = getMemberDetailsData.designation;
    tmepInitials.jobLevelId = getMemberDetailsData.jobLevelId;
    tmepInitials.emailAddress = getMemberDetailsData.email;
    setPointOfContactForm(temp);
  }

  const saveButton = () => {
    const accountInformationData = accountInformationRef.current.getFormData();
    const membershipSubscriptionData = membershipSubscriptionRef.current.getFormData();
    const organisationDetailsData = organisationDetailsRef.current.getFormData();
    const pointOfContactFData = pointOfContactFRef.current.getFormData();

    if (membershipSubscriptionData && organisationDetailsData && pointOfContactFData) {
      const request = {
        CorporateMembersId: corporateMemberId,
        IsQRScan: accountInformationData.isCorporateQRScan,
        IsBlockDataPurging: accountInformationData.isBlockDataPurging,
        StatusId: accountInformationData.accountStatus.value || accountInformationData.accountStatus,
        InstitutionName: organisationDetailsData.nameOfOrganisationAsInAcra,
        UENNo: organisationDetailsData.brnUenNo,
        AddressLine1: organisationDetailsData.organisationAddressLine1,
        AddressLine2: organisationDetailsData.organisationAddressLine2,
        AddressLine3: organisationDetailsData.organisationAddressLine3,
        PostalCode: organisationDetailsData.postalCode,
        IndustryCodeId: organisationDetailsData.industryCode?.value ? organisationDetailsData.industryCode.value : organisationDetailsData.industryCode,
        AcraBizFileName: fileUploadData?.fileName ? fileUploadData.fileName : organisationDetailsData.acraBizFile,

        MembershipTypeId: membershipSubscriptionData.membershipType?.value ? membershipSubscriptionData.membershipType.value : membershipSubscriptionData.membershipType,
        MembershipTenureId: membershipSubscriptionData.membershipTenure?.value ? membershipSubscriptionData.membershipTenure.value : membershipSubscriptionData.membershipTenure,
        StartDate: membershipSubscriptionData.preferredStartDate,
        SubscriptionStartDate: membershipSubscriptionData.membershipStartDate,
        SubscriptionEndDate: membershipSubscriptionData.membershipEndDate,

        SalutationId: pointOfContactFData?.salutationsID?.value || pointOfContactFData?.salutationsID,
        NameOfContactPerson: pointOfContactFData.nameOfContactPerson,
        LastName: pointOfContactFData.lastName,
        ContactNo: pointOfContactFData.contactNumber,
        Designation: pointOfContactFData.contactPersonsDesignation,
        JobLevelId: pointOfContactFData?.jobLevelId?.value ? pointOfContactFData?.jobLevelId?.value : pointOfContactFData?.jobLevelId,
        Email: pointOfContactFData.emailAddress,
      }
      updateCorporateMemberById(request)
    }
    else {
      error("Fill in all required fields")
    }
  }

  const handleOnChangeFileUpload = (dataField, data) => {
    let pathName = dataField === "acraBizFile" && "ACRA_FILE";
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", pathName);
    uploadPDF(formData);
  }
  const handleChangeFileDownload = async () => {
    let request = {
      type: "ACRA_FILE",
      fileName: fileUploadData?.fileName || acraFileName,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }

  const accordionData = [
    {
      id: 'accountInformation',
      header: 'Account Information',
      body: <CorporateAccountInformation
        formData={accountInformationForm}
        uenNumber={uenNumber}
        ref={accountInformationRef}
      />
    },
    {
      id: 'membershipSubscription',
      header: 'Membership Subscription',
      body: <CorporateMembershipSubscription formData={membershipSubscriptionForm} ref={membershipSubscriptionRef} />
    },
    {
      id: 'organisationDetails',
      header: 'Organisation Details',
      body: <OrganisationDetails formData={organisationDetailsForm} ref={organisationDetailsRef} handleOnChangeFileUpload={handleOnChangeFileUpload} handleChangeFileDownload={handleChangeFileDownload} />
    },
    {
      id: 'pointOfContact',
      header: 'Point of Contact',
      body: <CorporatePointOfContact formData={pointOfContactForm} ref={pointOfContactFRef} />
    },
    {
      id: 'financialRecord',
      header: 'Financial Record',
      body: <FinancialRecord
        corporateMemberId={corporateMemberId}
        isCorporateMember={isCorporateMember}
      />
    }
  ];
  const onBackButton = () => {
    navigate("/viewCorporateMembers")
  }
  return (
    <>
      <div className='common-accordian-view-design'>
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {accordionData.map(item => (
            <Accordion.Item key={item.id} eventKey={item.id} >
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>{item.body}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div className="mb-3">
            <Buttons
              buttonText="Back"
              buttonTypeClassName="back-btn"
              onClick={onBackButton}
            />
          </div>
          <div className="mb-3">
            <Buttons
              buttonText="Save"
              buttonTypeClassName="theme-btn"
              onClick={saveButton}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default EditCorporateMembersForm;